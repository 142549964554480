// Imports
// ------
import React from 'react';
import HTMLParser from '@parts/HTMLParser';
import { Row, Column } from '@waffl';

// Styles
// ------
import { Jacket, Content, Title } from './styles';

// Component
// ------
function AccountTerms({ data }) {
	return (
		<Jacket>
			{data.map(({ title, content }, i) => {
				const titleID = title.replace(/\s/g, '').toLowerCase();

				return (
					<Row isExpanded sidePad key={i} id={titleID}>
						<Column small={12} large={8} pushOnLarge={2} mpad>
							<Content marTop>
								<Title>{title}</Title>
								<HTMLParser toParse={content} />
							</Content>
						</Column>
					</Row>
				);
			})}
		</Jacket>
	);
}

export default AccountTerms;
