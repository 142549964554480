import React, { useEffect } from 'react';
import Seo from '@lay/Seo';
import AccountTermsComponent from '@parts/AccountTerms';
import Newsletter from '@parts/Newsletter';
import Footer from '@parts/Footer';
import PageTransition from '@parts/PageTransition';
import { graphql } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

function AccountTerms({ transitionStatus, entry, exit, data }) {
    const ts = transitionStatus;
    const bp = useBreakpoint();

    useEffect(() => {
        const timer = setTimeout(
            () => {
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
            },
            bp.large ? 500 : 1000
        );

        return () => {
            clearTimeout(timer);
        };
    }, []);

    const accountTermsData = [
        {
            title: "1. Introduction",
            content: `
                <p>Please read these Terms and Conditions ("Terms") carefully as they govern your use of (including access to) the services provided by M-XR Limited, including our website and associated products (“M-XR Services”). By creating an account or otherwise using M-XR Services, you agree to these Terms. If you do not agree, you must not use M-XR Services.</p>
            `
        },
        {
            title: "Age and Eligibility Requirements",
            content: `
                <p>To use M-XR Services, you must:</p>
                <ul>
                    <li>Be at least 13 years old (or the equivalent minimum age in your country).</li>
                    <li>Have parental or guardian consent if you are under 18 years old.</li>
                    <li>Have the legal capacity to enter into a binding contract.</li>
                    <li>Reside in a country where the Services are available.</li>
                </ul>
                <p>If you do not meet these requirements, we may suspend or terminate your account.</p>
            `
        },
        {
            title: "2. The M-XR Services Provided by Us",
            content: `
                <p>Some features may be limited based on your location or device compatibility.</p>
                <p>Authentication with M-XR Services is provided via Auth0 (Okta Ltd.) which acts as the Data Processor. M-XR Ltd. is the Data Controller.</p>
                <h3>Service Limitations and Modifications</h3>
                <p>We strive to keep M-XR Services operational but may:</p>
                <ul>
                    <li>Temporarily suspend access for maintenance or technical reasons.</li>
                    <li>Modify, discontinue, or remove features without liability to you.</li>
                    <li>Restrict access based on legal and security considerations.</li>
                </ul>
                <p>M-XR is not liable for service interruptions due to government actions, third parties, or unforeseen circumstances.</p>
            `
        },
        {
            title: "3. Your Use of M-XR Services",
            content: `
                <h3>Account Creation and Security</h3>
                <p>You must create an account using accurate information.</p>
                <p>Your login credentials are personal and confidential.</p>
                <p>You must notify us immediately of any unauthorized use of your account.</p>
                <p>M-XR may suspend or terminate your account if we detect security concerns.</p>
                <h3>Permitted Use</h3>
                <p>You may use M-XR Services for both personal and commercial purposes, subject to the following constraints:</p>
                <ul>
                    <li>You must not use M-XR Services for illegal, harmful, or deceptive activities.</li>
                    <li>You must not infringe on any intellectual property rights or misrepresent affiliations with M-XR.</li>
                    <li>If using M-XR Services for commercial purposes, you must comply with applicable laws and not engage in fraudulent, misleading, or exploitative business practices.</li>
                </ul>
                <p>M-XR reserves the right to review commercial use cases and may suspend or restrict access if they violate these Terms or pose security risks.</p>
                <p>M-XR reserves the right to suspend or terminate accounts that violate these rules.</p>
            `
        },
        {
            title: "4. Content and Intellectual Property Rights",
            content: `
                <p>All trademarks, software, and content accessible via M-XR Services are owned or licensed by M-XR.</p>
                <p>You may not copy, modify, or distribute any content without written permission.</p>
                <p>Any feedback provided by you may be used by M-XR without compensation.</p>
            `
        },
        {
            title: "5. Customer Support, Information, Questions, and Complaints",
            content: `
                <p>For support inquiries or feedback on M-XR Services, please contact us at support@m-xr.com</p>
            `
        },
        {
            title: "6. Problems and Disputes",
            content: `
                <h3>Account Suspension and Termination</h3>
                <p>M-XR may terminate or suspend your access if:</p>
                <ul>
                    <li>You violate these Terms.</li>
                    <li>We stop providing the Service.</li>
                    <li>It is necessary to comply with legal obligations.</li>
                </ul>
                <h3>Liability Limitations</h3>
                <p>To the fullest extent permitted by law, M-XR is not liable for:</p>
                <ul>
                    <li>Indirect, incidental, or consequential damages.</li>
                    <li>Service interruptions or data loss.</li>
                    <li>Unauthorized access to your account.</li>
                </ul>
            `
        },
        {
            title: "7. About These Terms",
            content: `
                <h3>Changes to These Terms</h3>
                <p>We may update these Terms occasionally. We will notify users of major changes via email or a website notice. Continued use of M-XR Services after updates constitutes acceptance of the new Terms.</p>
                <h3>Governing Law</h3>
                <p>These Terms are governed by the laws of England and Wales. Disputes must be resolved in UK courts unless otherwise required by law.</p>
                <h3>Severability and Waiver</h3>
                <p>If any part of these Terms is unenforceable, the remaining sections remain valid. Failure to enforce a right does not waive our ability to do so in the future.</p>
                <p>If you have any questions about these Terms, please contact support@m-xr.com.</p>
                <p>By creating an account, you confirm that you have read and agreed to these Terms.</p>
                <p>Effective Date: 19/03/2025</p>
            `
        }
    ];

    return (
        <>
            <Seo data={data.globalSeo.seo} backupData={data.globalSeo.seo} />
            <PageTransition status={ts} entry={entry} exit={exit}>
                <AccountTermsComponent data={accountTermsData} />
            </PageTransition>
        </>
    );
}

export default AccountTerms;

// GraphQL
// ------
export const query = graphql`
	query AccountTerms {
		globalSeo: datoCmsOptionsGlobal {
			seo: seoMeta {
				title
				image {
					url
				}
				desc: description
				card: twitterCard
			}
		}
	}
`;
